import { arrayRange } from './helpers';

const BOOKS_FOR_PAGE = 20;
const AUTHORS_FOR_PAGE = 30;
const SERIES_FOR_PAGE = 30;
const TOP_HUNDRED = 100;
const REVIEWS_FOR_SLIDER = 10;
const PRERENDER_PAGES = 1;

const RUSSIAN_ALPHABET = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'];
const ENGLISH_ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const DAYS = arrayRange(1, 31, 1);
const MONTHS = [
  { name: 'Январь', value: '01' },
  { name: 'Февраль', value: '02' },
  { name: 'Март', value: '03' },
  { name: 'Апрель', value: '04' },
  { name: 'Май', value: '05' },
  { name: 'Июнь', value: '06' },
  { name: 'Июль', value: '07' },
  { name: 'Август', value: '08' },
  { name: 'Сентябрь', value: '09' },
  { name: 'Октябрь', value: '10' },
  { name: 'Ноябрь', value: '11' },
  { name: 'Декабрь', value: '12' },
];

const MONTH_NAMES = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

const YEARS = arrayRange(1923, 2018, 1);
const GENDER = [
  { name: 'Мужской', value: 'male' },
  { name: 'Женский', value: 'female' },
];
const FILTER_LANGS = [
  { lang: 'foreign', name: 'Зарубежные' },
  { lang: 'russian', name: 'Русские' },
  { lang: 'all', name: 'Все' },
];

const ABSOLUTE_URL = 'https://fenzin.org';

const DEV_ABSOLUTE_URL = '';
// Переменная для разработки, абсолютным url - для картинок/фрагментов текста/аудио
// const DEV_ABSOLUTE_URL = ABSOLUTE_URL;

const config = {
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Authorization',
  },
};

const API_URL = `${process.env.APP_URL}`;

const MENU_API = {
  news: `${API_URL}satellite/books/news?page=1&page_size=7&sort_reversed=false`,
  genres: `${API_URL}satellite/genres/popular?count=20`,
};

const REVALIDATE_TIME = 300;

const LITRES_AUTHORS = ['dem-mihaylov', 'tala-tocka', 'mayk-omer', 'ayrin-laks', 'andrey-usachev', 'lu-cysin'];

export {
  BOOKS_FOR_PAGE,
  RUSSIAN_ALPHABET,
  ENGLISH_ALPHABET,
  DAYS,
  MONTHS,
  YEARS,
  GENDER,
  MONTH_NAMES,
  API_URL,
  PRERENDER_PAGES,
  TOP_HUNDRED,
  AUTHORS_FOR_PAGE,
  SERIES_FOR_PAGE,
  FILTER_LANGS,
  ABSOLUTE_URL,
  DEV_ABSOLUTE_URL,
  REVIEWS_FOR_SLIDER,
  MENU_API,
  REVALIDATE_TIME,
  LITRES_AUTHORS,
  config,
};
